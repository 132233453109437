import * as React from "react"

const CsMartha = () => {
  return (
    <>
      <div className="row align-center martha-cs">
        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-12 large-11">
          <p className="strong color--powder font26 mb-0">Martha:</p>
          <p className="strong color--purple font22 mb-1">
            Indolent systemic mastocytosis (ISM) presenting with recurrent
            gastrointestinal symptoms
          </p>
          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Patient history and presentation:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Past medical history of chronic urticaria, managed with
                high-dose antihistamines
              </li>

              <li>Recent onset of persistent diarrhea</li>
              <li>
                Reported increased frequency of unexplained anaphylaxis with
                hypotension and syncope
              </li>
              <li>
                Referred to an allergist/immunologist by her primary care
                provider
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Diagnostic workup:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Allergist suspected SM and ordered a serum tryptase test and
                high-sensitivity KIT D816V assay
              </li>
              <li>
                Allergist referred patient to hematologist for bone marrow
                biopsy
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">Test results:</p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>Serum tryptase level of 110 ng/mL</li>
              <li>Positive for KIT D816V in peripheral blood</li>
              <li>
                Hematologist communicated Martha’s tryptase level and KIT
                mutation results to a hematopathologist, along with ordering a
                bone marrow biopsy
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-center">
            <p className="h3 mb-0">
              Based on bone marrow biopsy results and other diagnostic marker
              testing, the hematopathologist confirmed a diagnosis of ISM
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CsMartha
