import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import FooterCrown from "../components/template-partials/footer-crown"
import linkOutIcon from "../images/link_out_bold.svg"
import linkOutIconWhite from "../images/like_out_bold_white.svg"

import CasestudyModule from "../components/template-partials/case-study-module"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const CaseStudiesPage = () => (
  <Layout route="sm-profiles-case-studies">
    <Seo
      page="suspect-sm"
      title="SM Patient Profiles and Case Studies"
      description="Read hypothetical case studies of different patient profiles to understand how systemic mastocytosis (SM) may present differently in patients, and how they might be diagnosed."
    />
    <div className="row lg_mt-3 mt-1 align-center medium-align-left">
      <div className="columns small-12 large-12">
        <h1 className="color--purple text--watch-quinn">
          Explore case studies of the types of patients you may see in your
          practice
        </h1>
      </div>
    </div>
    <div
      id="case-studies"
      className="row lg_mt-1 mt-3 align-center medium-align-left"
    >
      <div className="columns small-12 large-12">
        <p className="bodyP text--regular color--purple mt-1">
          The profiles below are examples of different patient types, which may
          help you recognize patients in your practice who may be at risk of
          systemic mastocytosis (SM). Patient profiles are fictionalized through
          review of published literature and are not actual patients.
        </p>
      </div>
    </div>
    <div className="row lg_mt-1 mt-1 lg_mb-4 mb-3">
      <div className="columns text-center">
        <p className="lead strong color--purple mb-0 bodyP">
          Select each of the cases below to learn more
        </p>
      </div>
    </div>
    <CasestudyModule />
    <div className="row lg_mt-2 mt-2 lg_mb-1 mb-1">
      <div className="columns">
        <p className="lead color--purple mb-0 body14-footnote">
          KIT=KIT proto-oncogene, receptor tyrosine kinase.
        </p>
      </div>
    </div>

    <div className="row lg_mt-10 mt-7 lg_mb-7 mb-7 align-center medium-align-left">
      <div className="columns text-right small-12 medium-expand">
        <p className="h4 color--purple">
          Recognize patients like these in your practice? Learn
          <br className="show-for-large" /> more about treating SM
        </p>
        <Link
          className="btn btn--outline btn--icon uppercase mt-1 modal-link-bp link_out_hover"
          to="https://www.ayvakithcp.com/ism/"
          target="_blank"
        >
          EXPLORE A TREATMENT OPTION{" "}
          <img
            alt=""
            className="main-header__logo link_out_28 link_out_icon_blue"
            src={linkOutIcon}
          />
          <img
            alt=""
            className="main-header__logo link_out_28 link_out_icon_white"
            src={linkOutIconWhite}
          />
        </Link>
      </div>
    </div>
    <FooterCrown type="home" />
  </Layout>
)

export default CaseStudiesPage
