import * as React from "react"

const CsGreg = () => {
  return (
    <>
      <div className="row align-center greg-cs">
        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-12 large-11">
          <p className="strong color--powder font26 mb-0">Gregory:</p>
          <p className="strong color--purple font22 mb-1">
            Systemic mastocytosis with an associated hematologic neoplasm
            (SM-AHN) confirmed on second evaluation
          </p>
          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Patient history and presentation:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Gregory was initially diagnosed with chronic myelomonocytic
                leukemia (CMML)
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Diagnostic workup:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                At the time of CMML diagnosis, next-generation sequencing (NGS)
                on bone marrow detected KIT D816V mutation
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">Test results:</p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Based on KIT positive status, bone marrow was interrogated for
                SM
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-center">
            <p className="h3 mb-0">
              Results of the bone marrow biopsy led to Gregory’s diagnosis being
              revised as SM-AHN, where the AHN component was CMML
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CsGreg
