import * as React from "react"

const CsKath = () => {
  return (
    <>
      <div className="row align-center kath-cs">
        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-12 large-11">
          <p className="strong color--powder font26 mb-0">Katherine:</p>
          <p className="strong color--purple font22 mb-1">
            Indolent systemic mastocytosis (ISM) presenting with skin lesions
          </p>
          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Patient history and presentation:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                After presenting to her local dermatologist with bothersome
                maculopapular lesions on her torso and upper thigh, Katherine
                was diagnosed with cutaneous mastocytosis (CM)
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Diagnostic workup:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Katherine’s dermatologist biopsied a skin lesion and found mast
                cells
              </li>
              <li>
                Her dermatologist then ordered a serum tryptase test and a
                high-sensitivity KIT assay using peripheral blood
              </li>
              <li>
                Referred to a hematologist/oncologist for a bone marrow biopsy
                to confirm a diagnosis of CM or SM
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">Test results:</p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>Serum tryptase level of 10 ng/mL</li>

              <li>Positive for KIT D816V in peripheral blood</li>
              <li>
                Hematologist/oncologist communicated to a hematopathologist the
                clinical suspicion of SM along with the positive KIT test,
                leading to a bone marrow biopsy
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-center">
            <p className="h3 mb-0">
              The hematopathologist was able to confirm a diagnosis of ISM based
              on the bone marrow biopsy findings and the previous diagnostic
              marker results
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CsKath
