import * as React from "react"

const CsPeter = () => {
  return (
    <>
      <div className="row align-center peter-cs">
        <div className="columns small-12 large-12 lg_mb-2 mb-2 small-collapse medium-uncollapse">
          <div className="hr"></div>
        </div>

        <div className="columns small-12 large-11">
          <p className="strong color--powder font26 mb-0">Peter:</p>
          <p className="strong color--purple font22 mb-1">
            Indolent systemic mastocytosis (ISM) presenting with frequent
            diarrhea and nausea
          </p>
          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Patient history and presentation:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Presented to allergist/immunologist with bouts of hives, severe
                diarrhea, and fatigue
              </li>
              <li>
                Initially diagnosed with idiopathic mast cell activation
                syndrome (MCAS)
              </li>
              <li>
                Attempted to manage symptoms with over-the-counter medications
              </li>
              <li>
                Symptoms continued to worsen and Peter had to take a leave of
                absence from work
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">
              Diagnostic workup:
            </p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>
                Peter’s allergist suspected SM and ordered a serum tryptase test
                along with a high-sensitivity KIT D816V assay
              </li>
            </ul>
          </div>

          <div className="">
            <p className="strong bodyP color--purple mb-0">Test results:</p>
            <ul className="ul-standard color--purple text--regular indent_ul">
              <li>Serum tryptase level of 18 ng/mL</li>
              <li>Positive for KIT D816V mutation in peripheral blood</li>
              <li>
                Referred to hematologist/oncologist for bone marrow biopsy
              </li>
              <li>
                Hematologist/oncologist communicated Peter’s tryptase level and
                KIT mutation results to a hematopathologist and ordered a bone
                marrow biopsy
              </li>
            </ul>
          </div>
        </div>

        <div className="columns small-12 large-11 lg_mt-3 mt-3">
          <div className="yellow-card yellow-card--even yellow-card--right text-center">
            <p className="h3 mb-0">
              Hematopathologist diagnosed Peter with ISM based on bone marrow
              biopsy and additional diagnostic marker results
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default CsPeter
